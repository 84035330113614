.App {
  text-align: center;
  height: 100vh;
}

body {
  height: 100vh;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: fade-in-image 20s;
  }
}

.App-header {
  background-color: #070707;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(9px + 2vmin);
  color: black;
  font-family: "Helvetica Neue";
  font-weight: 400;
}

.text {
  width: 97vmin;
}

.message {
  font-size: calc(2px + 2vmin);
  color: rgba(0, 61, 31, 0.8);
}

.errormessage {
  margin-top: 5px;
  font-size: 15px;
  color: rgba(104, 1, 1, 0.4);
}

.input {
  height: 20px;
  padding: 1px;
}

.button-wrapper {
  text-align: right;
}

.button {
  background-color: rgba(9, 126, 67, 0.4);
  border: none;
  color: white;
  font-size: calc(4px + 2vmin);
  padding: 5px 9px;
  border-radius: 15px;
  margin: 10px 0px;
  cursor: pointer;
  font-family: "Helvetica Neue";
  font-weight: 300;
  transition: 0.3s all;
}

.button:hover {
  border: none;
  background-color: rgba(9, 126, 67, 0.6);
}

.button:focus {
  border: none;
  background-color: rgba(9, 126, 67, 0.5);
}

.field {
  width: 80%;
  height: 35px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.3s all;
  border: none;
  margin-top: 15px;
}
.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.field:focus {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  border: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(190, 188, 188);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(212, 212, 212);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(212, 212, 212);
}
